.searchBarCont{
    position: sticky;
    top: 60px;
    width: 100%;
    align-items: center;
    display: flex;    
    justify-content: center; 
    padding: 20px;   
}

.form-control:focus {
    
    box-shadow: none;
  }
  
  .form-control-underlined {
    font-size: 18px;
    text-align: center;
    
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding:5px ;
    width: 25%;
    min-width: 250px;    
  }  
  
  .form-control::placeholder {
    
    justify-content: center;
    text-align: center;
    font-size: 18px;
    color: #071a52a8;
    font-style: italic;
  }


/* navyblue   #071a52     */
/* orange   #f7933b     */
/* beige   #e7decf     */
/* red   #d32026     */