.blackOut {
    position: fixed;
    display: flex;
    background-color: rgba(0, 0, 0, 0.829);
    width: 100%;
    top: 0;
    margin: 0;
    padding: 0;
    height: 100%;  
    z-index: 1;     
}

.thePic {
    max-height: 90%;
    max-width: 98%;
    margin: auto;    
    border: 1px solid black;
}

.blackOut .strip {
    display: flex;
    position: absolute;
    color: white;
    margin: auto auto;
    bottom: 0;
    width: 100%;
    background-color: black;
    align-items: center;
    
}

.blackOut p {
 margin:2px auto;
 font-family: 'Times New Roman', Times, serif;
 letter-spacing: 1px;
 font-size: 18px;
}
