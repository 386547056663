.mainFooter {
  justify-content: space-between;
  height: 250px;
  background-color: #494949;
  position: fixed;
  display: grid;
  width: 100%;
  margin: 0 auto;
  transition: 0.4s;
  grid-template-columns: 20px 20px 20px;
  grid-template-rows: 40px 210px;
  border-top: 1px solid rgb(210, 210, 210);
}
.smallFooter {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  align-items: center;
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
}

.smallFooter a {
  display: flex;
  align-items: center;
  color: #d32026;
  font-size: 16px;
  text-decoration: none;
  font-family: Helvetica, sans-serif;
  padding: 0 20px;
  margin: 0 0;
  letter-spacing: 2px;
  transition: font-weight, letter-spacing 0.5s linear;
}

.smallFooter a:hover {
  color: black;
}

.smallFooter a:hover {
  cursor: pointer;
}

.smallFooter a:active {
  cursor: pointer;
  color: #f7933b;
}

.BigFooter {
  border-top: 1px solid white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  justify-content: center;
}

.contactTab {
  margin: 0 auto;
  width: 100%;
  height: 80%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contactTabCenter {
  border-left: 1px solid rgb(210, 210, 210);
  border-right: 1px solid rgb(210, 210, 210);
}

.tag {
  display: grid;
  grid-template-columns: 35% 65%;
  width: 100%;
  margin: 0 auto;
  justify-content: left;
  align-items: center;
  text-decoration: none;
}

#tagCenter {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.tag:hover {
  color: #d32026;
}

.tag img {
  grid-column-start: 1;
  grid-column-end: 2;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  justify-self: right;
}

.tag p {
  font-family: Helvetica, sans-serif;
  color: rgb(210, 210, 210);
  font-size: 14px;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-self: left;
}

.DDLogo {
  width: 15%;
  max-width: 150px;
}

/* navyblue   #071a52     */
/* orange   #f7933b     */
/* beige   #e7decf     */
/* red   #d32026     */
