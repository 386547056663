.heroCoverDD {
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
    filter: blur(1em)  ;    
    display: flex;
    z-index: -1;
    margin-bottom: 0;
    background-size: cover;

}

.fullService {    
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;    
    align-items: center;    
    width:100%; 
    padding: 33px 10% 33px;
    margin: 0 auto 0 auto;
    overflow: hidden;   
    border-top: 1px solid #494949;
    border-bottom: 1px solid #494949 ;    
    gap: 10px;
}


.fullService h2 {    
    width:100%; 
    line-height: 1.5;   
    text-align: center;
    margin: 0 auto;
    color: rgb(75, 75, 75);   
    text-decoration: none;
    font-family: 'Josefin Sans' , 'Arial', 'Helvetica', sans-serif;
}



.fullService div {
    justify-content: center;
    font-family: 'Josefin Sans' , 'Arial', 'Helvetica', sans-serif;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0px auto 0 auto;
    padding: 0;
}

.fullService p {
    justify-self: center;
    margin: 0;
    padding: auto;
    letter-spacing: 1px;
}

.homeBody { 
   display: flex;
   flex-direction: column;   
    
}




/* navyblue   #071a52     */
/* orange   #f7933b     */
/* beige   #e7decf     */
/* red   #d32026     */
/* grey         rgb(210, 210, 210);     */
/* light black  #2e2e2e                 */



@media only screen and (max-width: 750px) {
    .fullService1 {
        font-size: 16px;
    }   

    .fullService2 {
        font-size: 15px;
    }

    .fullService {    
        padding:18px 2% 18px;    
    } 

    .fullService p {        
        font-size: 14px;
    }
}