.mainCardsCont {
  width: 100%;
  margin: 10px auto;
  padding: 25px 50px;
  background-color: white;
  display: grid;
  gap: 10px;  
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  border-top: 1px solid  #494949;
  border-bottom: 1px solid  #494949;
}

.cardCont {
  position: relative;
  margin: auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin:5px auto;
  transition: 0.5s;
  top: 0;
}

.cardImg:hover {
  box-shadow: 0px 0px 0px 0px black;
  top:10px;
  transition: 0.2s;
}

.cardImg {
  background-position: center;
  width: 100%;
  height: 500px;
  padding-top: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.5s;
  position: relative;
  top: 0;
  box-shadow: 15px 15px 8px -5px #2e2e2e;

}

.cardLogo {
  margin: 0px auto 40px;
  width: 60%;
  filter: grayscale(1) invert(1);
  
}

.cardCont:active {
  position: relative;
  top: 0px;
  box-shadow: 0px 0px 0px 0px black;
  transition: 0.1;
}

@media only screen and (max-width: 750px) {
  .mainCardsCont {
    padding: 15px 10px;
    gap: 10px;
    border-bottom: 0px solid  #494949;
  }

  .cardLogo {
    margin: 10px auto;
    width: 90%;
  }

  .cardImg {
   height: 200px;
   
  }
}

/* navyblue   #071a52     */
/* orange   #f7933b     */
/* beige   #e7decf     */
/* red   #d32026     */
