.fixedNav { 
    height: 70px;    
    align-items: center;
    background-color: #494949;
    width: 100%;
    position: sticky;    
    top:0;    
    display: grid;    
    grid-template-columns: 25% 55% 20%;
    grid-template-rows: 1;
    align-items: top;
    margin: 0 auto;      
    z-index: 1;
    border-bottom: 1px solid white; 
}

.logo {       
    display: flex;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: 2;  
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 2; 
   
}

.logo img {
    width: 80%;             
    margin: 10px 0;
    z-index: 2;
    
}

.logo img:hover {
   cursor: pointer;
    
}

.links {   
    justify-self: right;     
    width: 98%;
    height: 32px;    
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;    
    display: flex;
    justify-content: right;
    align-items: center;   
    
    
}

.links nav {
    height: 100%;
    display: flex;
    flex-wrap: wrap;     
    justify-content: space-around;
    width: 75%; 
    align-items: center;     
    
}

.singleLink {
    height: fit-content;
    display: flex;
    align-items: center;
    color: white ;
    font-size: 14px;
    text-decoration: none;   
    font-family: 'Josefin Sans' , 'Arial', 'Helvetica', sans-serif;
    padding: 0px 5px;
    font-weight: bold;    
    margin: 0 0;
    letter-spacing: 2px;        
    transition: font-weight,letter-spacing  .5s linear;  
    
}

.links span {
    font-size: 20px;
    
}

.singleLink:hover {    
    color: black;
}

.links a:hover {
    color: grey ;
    cursor: pointer;
}

.links a:active {
    cursor: pointer;
    color: #f7933b ;
}

.outLet {
    width: 100%;    
}

@media only screen and (max-width: 650px) {
    .singleLink {
        font-size: 10px;
        margin: 3px auto;
        
    }
    
    .fixedNav { 
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px; 
    }

    .logo img {
        width: 60%;                     
        margin-right: -10px;
        z-index: 2;
    }

    .links nav {
        margin: auto;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;     
        justify-content: space-around;
        width: 100%; 
        align-items: center;    
        padding-bottom: 10px; 
        
    }       

  }




/* navyblue     #071a52                 */
/* orange       #f7933b                 */
/* beige        #e7decf                 */
/* red          #d32026                 */
/* grey         rgb(210, 210, 210);     */
/* light black  #2e2e2e     #494949     */