.album {
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 98%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.card {
}

.smallPhoto {
  height: 300px;
}

.albumTitle {
  background-color: white;
  border-bottom: 10px solid rgb(210, 210, 210);
  border-top: 10px solid rgb(210, 210, 210);
  min-width: fit-content;
  width: 100%;
  padding: 20px 20px;
  margin: auto;
  margin-bottom: 10px;
  font-size: 25px;
  letter-spacing: 2px;
  text-decoration: none;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
}

.more {
  color: #d32026;
  font-size: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  font-family: Helvetica, sans-serif;
}

.more:hover {
  cursor: pointer;
}

.smallPhoto:hover {
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.7);
}

/* navyblue   #071a52     */
/* orange   #f7933b     */
/* beige   #e7decf     */
/* red   #d32026     */

@media only screen and (max-width: 1400px) {
  .smallPhoto {
    height: 200px;
  }
}

@media only screen and (max-width: 900px) {
  .albumTitle {
    padding: 10px 10px;
    font-size: 16px;
  }
  .smallPhoto {
    height: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .smallPhoto {
    height: 100px;
  }
}
