.projectCont {
    margin: auto;
    background-color:  rgb(210,210,210);
    margin-top: 10px;
    padding: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

/*
.projectCont:hover {
    cursor: pointer;
    
}
*/
.projectVis {
    justify-content: center;    
    align-items: center;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 60% 25% 15%;
    grid-template-rows: 80px auto auto;
}



.projectVis h2 {   
    display: flex; 
    margin: 10px auto;
    padding: 20px;    
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;    
    letter-spacing: 1px;  
    font-size: 25px;    
    font-family: 'Josefin Sans' , 'Arial', 'Helvetica', sans-serif;
}

.projectCov {
    display: flex;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}
.projectCov:hover {
    box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.7);
}

.projectVis p {
    line-height: 1.2;
    align-self: center;
    justify-self: left;
    text-align: left;
    margin: 0 auto;
    padding: auto;
    width: 90%;
    height: 100%;    
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
    letter-spacing: 1px;      
    font-family: 'Josefin Sans' , 'Arial', 'Helvetica', sans-serif;
}


.projectPlan {
    align-self: center;
    justify-self: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    width: 100%;
    height: auto;
}


.projectButton {
    margin: auto;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    margin-top: 10px;
    width: 60%;
    padding: 2px 0 ;
    height: 20px;    
    background-color: rgb(230, 230, 230);
    box-shadow: inset 0px 0px 10px 2px gray;
}

.projectButton:hover {
    background-color: rgb(160, 160, 160);
    cursor: pointer;
}

.projectButton img {
    
    width: 50px;
    height: 100%;
}


.projectHid {     
    margin-top: 10px;
    padding: 0px 10px;   
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;  
    text-align: center;
    align-items: center;
    width: 100%;    
        
}

.projectHid img {
    height: 300px;
}
.projectHid img:hover {
    box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.7);
    transition: 0.2s;
  }
@media only screen and (max-width: 1500px) {
    .projectVis {
        display: flex;
        flex-direction: column;
    }    

    .projectVis p {
        width: 98%;
        font-size: 14px;
        margin-top: 15px ;
    }

    .projectPlan {
        display: none;
    }
}


@media only screen and (max-width: 1400px) {
    .projectHid img {
      height: 200px;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .projectCont {
        padding-top: 5px;
    }
    
    .projectVis h2 {           
        margin: 0px auto;
        padding: 10px;           
        font-size: 15px;          
    }  
    
    .projectVis p {        
        letter-spacing: 1px;   
        font-size: 12px;              
    }
    .projectHid img {
      height: 150px;
    }

  }
  
  @media only screen and (max-width: 600px) {
    .projectHid img {
      height: 80px;
    }
  }
  








/* navyblue   #071a52     */
/* orange   #f7933b     */
/* beige   #e7decf     */
/* red   #d32026     */
