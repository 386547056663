.hero {
  align-self: center;
  background-color: rgb(210, 210, 210);
  padding: 0px 0;
  margin: 0px auto;
  display: grid;
  height: 510px;
  grid-template-columns: 100px 1fr 1fr 1fr 1fr 100px;
  grid-template-rows: 50px 1fr 1fr 1fr 1fr 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  /*  background-color: rgba(0, 0, 0, 0.15);  */
}

.furnText {
  font-size: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
  color: white;
  display: grid;
  grid-template-columns: 100%;
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 5;
  width: 100%;
  height: 100%;
  margin: auto auto;
}

.furnText h1 {
  text-align: center;
  justify-content: center;
  text-justify: justify;
  grid-column-start: 1;
  grid-column-end: 2;
  max-width: 650px;
  letter-spacing: 1px;
  width: 100%;
  margin: 10px auto;
  font-weight: 300;
}

.socHero {
  width: 100%;
  height: 100%;
  align-items: flex-end;
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 6;
  display: flex;
  justify-content: center;
}

.tagSocHero {
  display: flex;
  align-items: center;
  width: 40px;
  margin: 0 40px;
}

.tagSocHero img {
  width: 100%;
}

.tagSocHero p {
  margin-left: 15px;
  color: white;
}

.backImage {
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  background-position: center;
  display: flex;
  background-image: url("../../Pics/background/f1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 7;
}

.pngsmall {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 6;
  filter: invert(1);
}

.pngsmall img {
  justify-self: center;
  width: 100%;
  padding: 10%;
}

.furnLogo {
  margin: 0 auto;
  align-self: self-start;
  justify-self: center;
  min-width: 250px;
  width: 30%;
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
}

.sectionFurn {
  margin: 10px auto;
  padding: 10px 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: rgb(210, 210, 210);
  gap: 10px;
}

.subSectionFurn {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  flex-basis: 300px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 10px;
  margin: 0px 0px;
}

.sectionFurn h1 {
  letter-spacing: 1px;
  font-size: 25px;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
}

.sectionFurn h3 {
  letter-spacing: 1px;
  text-align: left;
  font-size: 18px;
  padding: 0 15px;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
}

.feedCont {
  justify-content: center;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
}

.map {
  justify-content: center;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
}

.map:hover {
}

.ourWork {
  color: white;
  background-color: #494949;
  font-size: 20px;
  width: 100%;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  margin: -10px auto;
  padding: 15px 0px;
}

.albums {
  height: 100%;
  padding: 30px 20px 25px 20px;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(350px, 1fr));
  width: 100%;
  column-gap: 30px;
  row-gap: 30px;
  margin: 10px 0px -10px 0px;
  align-items: center;
  justify-content: center;
  background-color: rgb(210, 210, 210);
}

.mainAlbum {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 350px;
}

.albumCont {
  grid-column-start:1 ;
  grid-column-end: 2;
  grid-row-start:1 ;
  grid-row-end:2 ;
  background-color: white;
  width: 100%;
  margin: auto;
  height: 350px;
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0px auto 0px;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  font-family: Helvetica, sans-serif;
  transition: 1s;
  box-shadow: 10px 10px 5px -5px #2e2e2e;
}

.albumCont:hover {
  width: 100%;
}

.albumName {  
  max-width: 600px;
  grid-column-start:1 ;
  grid-column-end: 2;
  grid-row-start:1 ;
  grid-row-end:2 ;
  letter-spacing: 1px;  
  font-size: 16px;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  align-self: flex-end; 
  justify-content: center;
  display: flex;  
  text-decoration: none;
  width:100%;  
  margin: auto auto;
  margin-bottom: 0px;
  padding: 7px 0px;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
  z-index: 1;
}

.albumName:hover {
  color: #d32026;
}

.albumBarCont {
  border: 0px solid rgb(210, 210, 210);
  position: relative;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  overflow: hidden;
}

.borderOnly1 {
  border-bottom: 2px solid white;
  display: none;
  padding-top: 10px;
  overflow: hidden;
}

.albumBar {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  height: 100%;
  position: relative;
  left: 0;
  overflow: visible;
}

.albumBar:hover {
  left: -6500px;
  transition: 100s linear;
  overflow: visible;
  transition-delay: 1;
}

#instaBar:hover {
  left: 0px;
}

#instaBar {
  animation: 200s linear 1s slide infinite;
}

@keyframes slide {
  0% {
    left: 0;
  }
  50% {
    left: -6500px;
  }
  100% {
    left: 0px;
  }
}

.albumBar img {
  height: 100%;
  /*
        border: 1px solid rgb(210, 210, 210);
        border-top: 10px solid rgb(210, 210, 210); 
        border-bottom: 10px solid rgb(210, 210, 210)
    */

  border-right: 3px solid white;
}

.loadingBack {  
  height: 200px;
  margin: 50px auto; 
  animation: 1.5s linear 0s loading infinite;
}

@keyframes loading {
  0% {
    filter: grayscale(0);
  }  
  50%{
    filter: grayscale(1);
  }
  100% {
    filter: grayscale(0);
  }
}

/* navyblue   #071a52     */
/* orange   #f7933b     */
/* beige   #e7decf     */
/* red   #d32026     */
/* background-color: rgb(210, 210, 210);  */

@media only screen and (max-width: 550px) {
}

@media only screen and (max-width: 750px) {
  .ourWork {   
    font-size: 16px;    
    padding: 10px 0px;
  }

  .albums {
    padding: 15px 10px 10px 10px;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .mainAlbum {    
    width: 100%;
    justify-content: center;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 150px;
    max-width: 250px;
  }

  .albumCont {
    height: 150px;
  }

  .furnText h1 {
    font-size: 20px;
    padding: 0px 10px;
  }

  .feedCont:hover {
    width: 110%;
  }

  .albumName {
    font-size: 11px;
    max-width: 250px;
    padding: 5px 5px;
  }

  .sectionFurn {
    padding-right: 10px;
    padding-left: 10px;
  }

  .subSectionFurn {
    height: 200px;
    flex-basis: 150px;
  }

  .sectionFurn h1 {
    margin: 0 auto;
    font-size: 14px;
    font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
  }

  .sectionFurn h3 {
    margin: auto;
    font-size: 12px;
    text-align: left;
    padding: 0 0px;
    font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
  }

  .feedCont {
    margin: auto;
    padding: 0;
    height: 80%;
  }

  
}

@media only screen and (min-width: 750px) {
  .feedCont:hover {
    position: relative;
    top: -125%;
    right: -50%;
    display: block;
    height: 200%;
    width: 200%;
  }
}
