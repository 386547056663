
.homeBody { 
   display: flex;
   flex-direction: column; 
   
    
}

.latestProjects {
    border-top: 1px solid #494949;
    border-bottom: 1px solid #494949;     
    font-size: 25px;
    width: 100%;
    font-family: 'Josefin Sans' , 'Arial', 'Helvetica', sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 20px;
}

@media only screen and (max-width: 900px) {
    .latestProjects {
        font-size: 16px;       
    }
}

