.backImageReal {
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: darken;
    background-position: center;
    display: flex;      
    background-image: url('../../Pics/background/r1.jpg');    
    background-repeat: no-repeat;
    background-size: cover;   
    width: 100%;     
    height: 100%;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 7;   
}