

.feedImg {      
      
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px;     
    background-color: rgb(210, 210, 210);
}

.feedImg:hover {
    
    padding: 5px;
}

.feedCont h1 {
    grid-row-start: 1;
    grid-row-end: 2; 
}


/*
.coverInfo {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    background-color: rgba(0, 0, 0, 0.0);    
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s linear;
}

.theFeed h1 {
     color:aliceblue;
     opacity: 0;    
     width: 100%;
     font-size: 14px;
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100%;
     transition: .2s linear;
}

.coverInfo:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.theFeed h1:hover {
    opacity: 1;
}
*/