.aboutCont {
  justify-content: center;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;  
}

.aboutBox {
  grid-column-start: 1;
  grid-column-end: 2;
  align-items: center;
  width: 60%;
  min-width: 350px;
  margin: 100px auto;
  display: grid;
  grid-template-rows: 60px 1fr;
  background-color: white;
}

.aboutHead {
  gap: 10px;
  width: 100%;
  min-width: 200px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  background-color: white;
}

.aboutHead h1 {
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
  width: 100%;
  background-color: rgb(210, 210, 210);
  margin: auto;
  font-size: 14px;
  font-weight: 500;
  color: #071a52;
  letter-spacing: 2px;
  transition: 0.3s;
  line-height: 3;
  transition: 0.2s linear;
}

.aboutHead h1:hover {
  cursor: pointer;
  background-color: rgb(160, 160, 160);
}

.aboutBody {
  background-color: rgb(210, 210, 210);
  grid-row-start: 2;
  padding: 10px 10px;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  transition: 0.3s;
}

.aboutUS {
  background-color: white;
  padding: 20px;
  padding-left: 15%;
  padding-right: 15%;
}

.aboutBody p {
  font-size: 16px;
  line-height: 1.3;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
  text-justify: justify;
  text-align: left;
}

.whyUS {
  margin: 10px 0px;
  font-size: 22px;
  line-height: 1.3;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
}

.whatWeDoCont {
  background-color: rgb(210, 210, 210);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.whatWeDo {
  text-align: left;
  font-family: "Josefin Sans", "Arial", "Helvetica", sans-serif;
  background-color: white;
  flex-basis: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.whatWeDo h1 {
  color: rgb(160, 160, 160);
  margin: auto auto;
  font-size: 150px;
  grid-row-start: 1;
  grid-row-end: 3 span;
  grid-column-start: 1;
  grid-column-end: 2;
  text-align: left;
}

.whatWeDo h2 {
  color: #071a52;
  margin: 10px 0px;
  align-self: start;
  padding: 0;
  grid-column-start: 2;
  grid-column-end: 3;
  font-size: 18px;
}

.whatWeDo p {
  font-weight: 500;
  color: #071a52;
  align-self: start;
  font-size: 14px;
  margin: 5px;
  grid-column-start: 2;
  grid-column-end: 3;
  padding: 0px 20px 0px 10px;
}

.hisCont {
    display: flex;
    flex-direction: column;
    width: 100%;   
    row-gap: 10px;
}

.hisImg {
    width: 100%;
}

@media only screen and (max-width: 900px) { 

  .aboutBox {
    margin: 30px auto;
  }

  .aboutUS {
    background-color: white;
    padding: 10px;    
  }

  .aboutUS p{
    font-size: 12px;
  }

  .whyUS {
    font-size: 16px;
    text-align: left;
  }

  .aboutHead h1 {
    font-size: 12px;
  }

  .whatWeDo {    
    flex-basis: 300px;
    
  }
  
  .whatWeDo h1 {    
    font-size: 75px;    
  }
  
  .whatWeDo h2 {    
    font-size: 14px;
  }
  
  .whatWeDo p {    
    font-size: 12px;    
  }
  
}



/* navyblue   #071a52     */
/* orange   #f7933b     */
/* beige   #e7decf     */
/* red   #d32026     */
